import { Title, Text, Stack, Container } from '@mantine/core';

export default function Bio() {
    return (
        <Stack align="flex-start" >
            <Container>
                <Title order={2}>Summary</Title>
                <Text>
                    Annie is a software engineer focused on MLOps and full-stack development. She is interested in wearable and XR technologies, particularly in the fashion and healthcare industries.
                </Text>
            </Container>
            <Container>
                <Title order={2}>Experience</Title>
                <Text>
                    She has professional experience in enterprise software development, and further experience in embedded systems, signal processing, and game development.
                </Text>
            </Container>
            <Container>
                <Title order={2}>Education</Title>
                <Text>
                    Her education includes a Bachelor's in Electrical Engineering and a Master's in Material Science; both from the University of Washington.
                </Text>
            </Container>
            <Container>
                <Title order={2}>Personal Interests</Title>
                <Text>
                    Annie's personal interests include fashion, movies, music, and writing.
                </Text>
            </Container>
        </Stack>
    );
  }