import Bio from './components/Bio.js'
import { Stack, Paper, Avatar } from '@mantine/core';

function App() {
  return (
    <Stack align="center" withBorder>
      <Paper shadow="xs">
        <Avatar  radius="lg" size={200} color="pink" />
      </Paper>
      <Paper shadow="xs">
        <Bio/>
      </Paper>
    </Stack>
  );
}

export default App;
